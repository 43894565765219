import React from 'react';
import logo from '@static/logo.png';
import { Link } from 'gatsby';
import { usePageContext } from './pageContext';

const Header = (): React.ReactElement => {
    const { langKey } = usePageContext();
    const homepath = langKey === 'fi' ? '' : `${langKey}/`;
    return (
        <div className="container is-hidden-touch mb-4">
            <div className="columns">
                <div className="column is-narrow">
                    <Link to={`/${homepath}`}>
                        <img
                            style={{ maxWidth: '100%', width: 'auto' }}
                            className="image"
                            src={logo}
                            alt="logo"
                            height="80"
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Header;
