import React, { useCallback, useState } from 'react';
import logo from '@static/logo.png';
import fi from '@static/fi.svg';
import en from '@static/gb.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { usePageContext } from './pageContext';
import useOutsideAlerter from './useOutsideClickDetection';

const NavLink = ({ to, onClick, children }: { to: string; children: any; onClick: () => void }) => {
    return (
        <Link to={to} className="navbar-item" onClick={onClick}>
            {children}
        </Link>
    );
};

const Navbar = (): React.ReactElement => {
    const { t } = useTranslation();
    const { originalPath, slug, langKey } = usePageContext();
    const [navVisible, setNavVisible] = useState<boolean>(false);
    const handleNavToggle = useCallback(() => {
        setNavVisible((state) => !state);
    }, []);
    const navbarRef = React.useRef(null);
    useOutsideAlerter(
        navbarRef,
        useCallback(() => setNavVisible(false), [])
    );

    const homePath = langKey === 'fi' ? '/' : `/${langKey}`;
    const pathPrefix = langKey === 'fi' ? '' : `/${langKey}`;
    const defaultUrl = originalPath.replace('/en', '');
    const navMenuClasses = navVisible ? 'navbar-menu is-active' : 'navbar-menu';

    return (
        <nav
            ref={navbarRef}
            className="navbar box p-0 mb-4"
            role="navigation"
            aria-label="main navigation"
        >
            <div className="container">
                <div className="navbar-brand">
                    <Link to={`${homePath}`} className="navbar-item is-hidden-desktop pl-4">
                        <img src={logo} alt="logo" height="80" />
                    </Link>
                    <div>
                        <a
                            role="button"
                            className="navbar-burger"
                            aria-label="menu"
                            aria-expanded="false"
                            data-target="navbar-menu"
                            onClick={handleNavToggle}
                            style={{ alignSelf: 'top' }}
                        >
                            <span aria-hidden="true" />
                            <span aria-hidden="true" />
                            <span aria-hidden="true" />
                        </a>
                        <div className="is-hidden-desktop">
                            {langKey === 'en' && (
                                <Link to={defaultUrl} className="navbar-item">
                                    <span className="icon">
                                        <img src={fi} alt="finnish flag" />
                                    </span>
                                </Link>
                            )}
                            {langKey === 'fi' && (
                                <Link
                                    to={`/en${slug.startsWith('/en/') ? '' : slug}`}
                                    className="navbar-item"
                                >
                                    <span className="icon">
                                        <img src={en} alt="english flag" />
                                    </span>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
                <div id="navbar-menu" className={navMenuClasses}>
                    <div className="navbar-start pl-4" role="menuitem">
                        <div className="divider my-0" />
                        <NavLink to={`${pathPrefix}/`} onClick={handleNavToggle}>
                            {t('navigation.link.frontpage')}
                        </NavLink>

                        <div className="navbar-item has-dropdown is-hoverable">
                            <NavLink to={`${pathPrefix}/club/`} onClick={handleNavToggle}>
                                {t('navigation.link.background')}
                            </NavLink>
                            <div className="navbar-dropdown">
                                <NavLink to={`${pathPrefix}/images/`} onClick={handleNavToggle}>
                                    {t('navigation.link.images')}
                                </NavLink>
                            </div>
                        </div>

                        <div className="navbar-item has-dropdown is-hoverable">
                            <NavLink to={`${pathPrefix}/gojuryu/`} onClick={handleNavToggle}>
                                {t('navigation.link.gojuryu')}
                            </NavLink>
                            <div className="navbar-dropdown">
                                <NavLink to={`${pathPrefix}/hokama/`} onClick={handleNavToggle}>
                                    {t('navigation.link.hokama')}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="navbar-end">
                        <div className="is-hidden-touch pr-4 pt-2">
                            {langKey === 'en' && (
                                <Link to={defaultUrl} className="navbar-item">
                                    <span className="icon">
                                        <img src={fi} alt="finnish flag" />
                                    </span>
                                </Link>
                            )}
                            {langKey === 'fi' && (
                                <Link
                                    to={`/en${slug.startsWith('/en/') ? '' : slug}`}
                                    className="navbar-item"
                                >
                                    <span className="icon">
                                        <img src={en} alt="english flag" />
                                    </span>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
