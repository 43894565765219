import React from 'react';

type PageContext = {
    slug: string;
    langKey: string;
    originalPath: string;
};

const PageContext = React.createContext<PageContext>({
    slug: '',
    langKey: '',
    originalPath: '',
});

export const PageContextProvider = ({
    context,
    children,
}: {
    context: PageContext;
    children: React.ReactNode;
}): React.ReactElement => {
    return <PageContext.Provider value={context}>{children}</PageContext.Provider>;
};

export const usePageContext = (): PageContext => React.useContext(PageContext);
