import React, { ReactElement } from 'react';
import { I18nextProvider } from 'react-i18next';
import { PageContextProvider } from './src/components/pageContext';
import i18next from './src/localization';

export const wrapRootElement = ({ element }: { element: ReactElement }): ReactElement => {
    return <I18nextProvider i18n={i18next}>{element}</I18nextProvider>;
};

export const wrapPageElement = ({
    element,
    props,
}: {
    element: ReactElement;
    props: any;
}): ReactElement => {
    if (i18next.language !== props.pageContext.langKey) {
        i18next.changeLanguage(props.pageContext.langKey || 'fi');
    }
    return <PageContextProvider context={props.pageContext}>{element}</PageContextProvider>;
};
