// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-club-index-en-tsx": () => import("./../../../src/pages/club/index.en.tsx" /* webpackChunkName: "component---src-pages-club-index-en-tsx" */),
  "component---src-pages-club-index-tsx": () => import("./../../../src/pages/club/index.tsx" /* webpackChunkName: "component---src-pages-club-index-tsx" */),
  "component---src-pages-contact-index-en-tsx": () => import("./../../../src/pages/contact/index.en.tsx" /* webpackChunkName: "component---src-pages-contact-index-en-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-gojuryu-index-en-tsx": () => import("./../../../src/pages/gojuryu/index.en.tsx" /* webpackChunkName: "component---src-pages-gojuryu-index-en-tsx" */),
  "component---src-pages-gojuryu-index-tsx": () => import("./../../../src/pages/gojuryu/index.tsx" /* webpackChunkName: "component---src-pages-gojuryu-index-tsx" */),
  "component---src-pages-hokama-index-en-tsx": () => import("./../../../src/pages/hokama/index.en.tsx" /* webpackChunkName: "component---src-pages-hokama-index-en-tsx" */),
  "component---src-pages-hokama-index-tsx": () => import("./../../../src/pages/hokama/index.tsx" /* webpackChunkName: "component---src-pages-hokama-index-tsx" */),
  "component---src-pages-images-index-en-tsx": () => import("./../../../src/pages/images/index.en.tsx" /* webpackChunkName: "component---src-pages-images-index-en-tsx" */),
  "component---src-pages-images-index-tsx": () => import("./../../../src/pages/images/index.tsx" /* webpackChunkName: "component---src-pages-images-index-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-members-index-en-tsx": () => import("./../../../src/pages/members/index.en.tsx" /* webpackChunkName: "component---src-pages-members-index-en-tsx" */),
  "component---src-pages-members-index-tsx": () => import("./../../../src/pages/members/index.tsx" /* webpackChunkName: "component---src-pages-members-index-tsx" */)
}

