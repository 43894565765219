import i18next, { Resource, ResourceLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en/translations.json';
import fi from './locales/fi/translations.json';

const resources: Resource = {
  en: en as ResourceLanguage,
  fi: fi as ResourceLanguage,
};

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'fi',
    fallbackLng: 'fi',
    ns: 'translation',
    defaultNS: 'translation',
    debug: true,
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  });

export default i18next;
