import React from 'react';
import Navbar from '@components/navbar';
import Header from '@components/header';
import { Helmet } from 'react-helmet';

const Layout = ({ children }: { children: React.ReactNode }): React.ReactElement => {
    return (
        <div id="main-container" className="container is-max-desktop mb-6">
            <Helmet title="Kenshikai Karate-Do" />
            <Header />
            <Navbar />
            {children}
        </div>
    );
};

export default Layout;
